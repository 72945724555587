<template>
  <div class="login">
    <md-card class="contentCard">
      <div class="md-title">{{ getContent(82) }}</div>
      <div class="md-subhead">
        {{ getContent(83) }}
        <a href="mailto:help@mindsupport.eu"> {{ getContent(84) }}</a
        >.
      </div>
      <md-field>
        <label>{{ getContent(85) }}</label>
        <md-input v-model="email" name="email" md-clearable></md-input>
      </md-field>
      <md-field>
        <label>{{ getContent(86) }}</label>
        <md-input
          v-model="password"
          name="password"
          type="password"
          md-clearable
        ></md-input>
      </md-field>

      <md-field v-if="embedded && supportType != 1">
        <label>{{ getContent(1245) }}</label>
        <md-textarea
          v-model="aboutMe"
          name="name"
          md-clearable
          md-autogrow
          maxlength="150"
        ></md-textarea>
      </md-field>
      <div>
        <a style="cursor: pointer" @click="forgotPW">{{ getContent(1104) }}</a>
      </div>
      <div v-if="loggingIn">
        <md-progress-bar md-mode="query"></md-progress-bar>
      </div>

      <md-checkbox
        type="checkbox"
        id="rememberMeBox"
        v-model="rememberMeChecked"
      ></md-checkbox>
      <label for="rememberMeBox" v-if="rememberMeChecked">{{
        getContent(87)
      }}</label>
      <label for="rememberMeBox" v-else>{{ getContent(88) }}</label>

      <br />
      <p v-if="loginError" class="warnings">{{ loginError }}</p>
      <md-card-actions>
        <md-button class="loginButton" @click="navigateSignup()"
          >Signup instead</md-button
        >
        <md-button
          class="md-raised md-primary loginButton"
          @click="loginSubmit()"
          >{{ getContent(89) }}</md-button
        >
      </md-card-actions>
    </md-card>
  </div>
</template>

<script>
import vueCookie from "vue-cookies";
import { mapState, mapActions } from "vuex";
import { loadData } from "@/utils";
import { axiosPost } from "@/utils/axiosWrapper";

export default {
  name: "Login",
  beforeMount() {
    this.checkCookie();
  },
  data() {
    return {
      rememberMeChecked: true,
      aboutMe: "",
      email: "",
      password: "",
      myLanguage: this.$router.currentRoute.params.language.toUpperCase(),
      text: {},
    };
  },
  props: {
    embedded: {
      type: Boolean,
      default: false,
    },
    answers: {
      type: Array,
      default: () => [],
    },
    supportType: {
      type: Number,
      default: null,
    },
  },
  computed: {
    ...mapState(["loggingIn", "loginError", "loginSuccessful"]),
  },
  methods: {
    ...mapActions(["doLogin"]),
    loginSubmit() {
      let loginData = {
        email: this.email,
        password: this.password,
        rememberMeChecked: this.rememberMeChecked,
        answers: null,
        route: this.$route.query.to,
      };
      if (this.embedded)
        loginData = {
          email: this.email,
          password: this.password,
          rememberMeChecked: this.rememberMeChecked,
          answers: this.answers,
          aboutMe: this.aboutMe,
          route: this.$route.query.to,
        };

      this.doLogin(loginData);
    },
    checkCookie: function () {
      if (vueCookie.get("email") != null && vueCookie.get("password") != null) {
        this.email = vueCookie.get("email");
        this.password = vueCookie.get("password");
      }
    },
    forgotPW: function () {
      axiosPost("/users/password/new", {
        email: this.email.toLowerCase(),
      })
        .then(() =>
          alert(
            "We just sent you a new temporary password.\nPlease change it after login."
          )
        )
        .catch((err) => console.error(err));
    },
    getContent: function (key) {
      return this.text[key];
    },

    navigateSignup: function () {
      if (this.embedded) {
        this.$emit("signup");
        return;
      }
      this.$router.push({ name: "Signup" });
    },
  },
  async created() {
    this.text = {
      ...(await loadData("SignIn", this.myLanguage)),
      ...(await loadData("Signup", this.myLanguage)),
    };
  },
};
</script>
<style scoped>
.login {
  text-align: left;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.contentCard {
  height: max-content;
  padding: 2em;
  width: 50em;
}

.loginButton {
  margin-left: 0;
}

md-progress-bar {
  margin: 10px;
}

.warnings {
  padding-bottom: 30px;
  color: #e53935;
}
</style>
